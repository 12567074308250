import storeService from './storeService';
import actions from '../store/actions/types';
import IntegrationParams from '../types/IntegrationParams';
import Mode from '../types/Mode';
import WarningType from '../types/WarningType';
import urlParams from './urlParams';

class DispatcherService {
    setParamsByIframe = (): void => {
        let iframeParams = urlParams.getUrlParams(window.location.href);

        const token = iframeParams.token,
            unti_id = iframeParams.unti_id,
            activity_uuid = iframeParams.activity_uuid;

        storeService.getStore().dispatch({
            type: actions.general.SET_INTEGRATION_PARAMS,
            payload: {
                token: token,
                unti_id: unti_id,
                activity_uuid: activity_uuid,
            } as IntegrationParams,
        });
    };

    setParamsByElement = (element: HTMLElement | null): void => {
        if (element) {
            const token = element.getAttribute('data-user-token'),
                unti_id = element.getAttribute('data-unti-id'),
                activity_uuid = element.getAttribute('data-activity-uuid');

            storeService.getStore().dispatch({
                type: actions.general.SET_INTEGRATION_PARAMS,
                payload: {
                    token: token,
                    unti_id: unti_id,
                    activity_uuid: activity_uuid,
                } as IntegrationParams,
            });
        }
    };

    setOptions = (options: string[]) => {
        storeService.getStore().dispatch({
            type: actions.general.SET_OPTIONS,
            payload: options,
        });
    };

    setNodes = (nodes: any) => {
        storeService.getStore().dispatch({
            type: actions.general.SET_NODES,
            payload: nodes,
        });
    };

    setGraphOption = (option: string[]) => {
        storeService.getStore().dispatch({
            type: actions.general.SET_GRAPH_OPTION,
            payload: option,
        });
    };

    setMode = (mode: Mode) => {
        storeService.getStore().dispatch({
            type: actions.general.SET_MODE,
            payload: mode,
        });
    };

    setShouldRedirect = (shouldRedirect: boolean) => {
        storeService.getStore().dispatch({
            type: actions.general.SET_SHOULD_REDIRECT,
            payload: shouldRedirect,
        });
    };

    setWarningType = (type: WarningType): void => {
        storeService.getStore().dispatch({
            type: actions.general.SET_WARNING_TYPE,
            payload: type,
        });
    };
}

const dispatherService = new DispatcherService();
export default dispatherService;
