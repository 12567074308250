const actions = {
    general: {
        FETCH_CONFIG: 'FETCH_TEAMS',
        FETCH_CONFIG_SUCCESS: 'FETCH_TEAMS_SUCCESS',
        FETCH_CONFIG_ERROR: 'FETCH_TEAMS_ERROR',
        SET_OPTIONS: 'SET_OPTIONS',
        SET_NODES: 'SET_NODES',
        SET_GRAPH_OPTION: 'SET_GRAPH_OPTION',
        SET_MODE: 'SET_MODE',
        SET_INTEGRATION_PARAMS: 'SET_INTEGRATION_PARAMS',
        SET_SHOULD_REDIRECT: 'SET_SHOULD_REDIRECT',
        SET_WARNING_TYPE: 'SET_WARNING_TYPE',
    },
};

export default actions;
