import ICategory from '../../types/Graph/Category';

const categories: ICategory[] = [
    {
        name: 'Мышление',
        required: false,
        options: [
            'Хорошо умею',
            'Более-менее умею',
            'Не умею и хочу научиться',
            'Мне не требуется',
        ],
    },
    {
        name: 'Коммуникация',
        required: false,
        options: [
            'Хорошо умею',
            'Более-менее умею',
            'Не умею и хочу научиться',
            'Мне не требуется',
        ],
    },
    {
        name: 'Креативность',
        required: false,
        options: [
            'Хорошо умею',
            'Более-менее умею',
            'Не умею и хочу научиться',
            'Мне не требуется',
        ],
    },
    {
        name: 'Исполнение',
        required: false,
        options: [
            'Хорошо умею',
            'Более-менее умею',
            'Не умею и хочу научиться',
            'Мне не требуется',
        ],
    },
    {
        name: 'Критика и контроль',
        required: false,
        options: [
            'Хорошо умею',
            'Более-менее умею',
            'Не умею и хочу научиться',
            'Мне не требуется',
        ],
    },
    {
        name: 'Организация',
        required: false,
        options: [
            'Хорошо умею',
            'Более-менее умею',
            'Не умею и хочу научиться',
            'Мне не требуется',
        ],
    },
    {
        name: 'Мои задачи в рамках команды',
        required: true,
        options: [
            'Моя основная функция',
            'Моя дополнительная функция',
            'Направление моего развития',
        ],
    },
];

export default categories;
