import IGraph from '../../types/Graph/Graph';

const graph: IGraph = {
    nodes: [
        {
            id: '1',
            name: 'Мыслить логически\nи аналитически',
            attributes: ['Мышление', '', 'Мыслить логически и аналитически'],
            category: 'Мышление',
            draggable: true,
            symbolSize: 25,
            itemStyle: null,
        },
        {
            id: '2',
            name: 'Мыслить\nнестандартно',
            attributes: ['Мышление', '', 'Мыслить логически и аналитически'],
            category: 'Мышление',
            draggable: true,
            symbolSize: 25,
            itemStyle: null,
        },
        {
            id: '3',
            name: 'Решать\nсложные\nпроблемы',
            attributes: ['Мышление', '', 'Решать сложные проблемы'],
            category: 'Мышление',
            draggable: true,
            symbolSize: 25,
            itemStyle: null,
        },
        {
            id: '4',
            name: 'Анализировать\nс разных\nсторон',
            attributes: ['Мышление', '', 'Анализировать с разных сторон'],
            category: 'Мышление',
            draggable: true,
            symbolSize: 25,
            itemStyle: null,
        },
        {
            id: '5',
            name: 'Заниматься\nпоиском',
            attributes: ['Мышление', '', 'Заниматься поиском'],
            category: 'Мышление',
            draggable: true,
            symbolSize: 25,
            itemStyle: null,
        },
        {
            id: '6',
            name: 'Выступать\nна публике',
            attributes: ['Коммуникация', '', 'Выступать на публике'],
            category: 'Коммуникация',
            draggable: true,
            symbolSize: 25,
            itemStyle: null,
        },
        {
            id: '7',
            name: 'Проводить\nпереговоры',
            attributes: ['Коммуникация', '', 'Проводить переговоры'],
            category: 'Коммуникация',
            draggable: true,
            symbolSize: 25,
            itemStyle: null,
        },
        {
            id: '8',
            name: 'Улаживать\nконфликты',
            attributes: ['Коммуникация', '', 'Улаживать конфликты'],
            category: 'Коммуникация',
            draggable: true,
            symbolSize: 25,
            itemStyle: null,
        },
        {
            id: '9',
            name: 'Воодушевлять\nколлег',
            attributes: ['Коммуникация', '', 'Воодушевлять коллег'],
            category: 'Коммуникация',
            draggable: true,
            symbolSize: 25,
            itemStyle: null,
        },
        {
            id: '10',
            name: 'Налаживать\nконтакты,\nсвязи',
            attributes: ['Коммуникация', '', 'Налаживать контакты, связи'],
            category: 'Коммуникация',
            draggable: true,
            symbolSize: 25,
            itemStyle: null,
        },
        {
            id: '11',
            name: 'Генерировать\nидеи',
            attributes: ['Креативность', '', 'Генерировать идеи'],
            category: 'Креативность',
            draggable: true,
            symbolSize: 25,
            itemStyle: null,
        },
        {
            id: '12',
            name: 'Работать\nс новизной\nи неопределённостью',
            attributes: ['Креативность', '', 'Работать с новизной и неопределённостью'],
            category: 'Креативность',
            draggable: true,
            symbolSize: 25,
            itemStyle: null,
        },
        {
            id: '13',
            name: 'Переформулировать\nи упаковывать идеи',
            attributes: ['Креативность', '', 'Переформулировать и упаковывать идеи'],
            category: 'Креативность',
            draggable: true,
            symbolSize: 25,
            itemStyle: null,
        },
        {
            id: '14',
            name: 'Проектировать\nи конструировать',
            attributes: ['Креативность', '', 'Проектировать и конструировать'],
            category: 'Креативность',
            draggable: true,
            symbolSize: 25,
            itemStyle: null,
        },
        {
            id: '15',
            name: 'Оформлять\nи сводить\nрезультаты работ',
            attributes: ['Креативность', '', 'Оформлять и сводить результаты работ'],
            category: 'Креативность',
            draggable: true,
            symbolSize: 25,
            itemStyle: null,
        },
        {
            id: '16',
            name: 'Просто\nделать\nсвое дело',
            attributes: ['Исполнение', '', 'Просто делать свое дело'],
            category: 'Исполнение',
            draggable: true,
            symbolSize: 25,
            itemStyle: null,
        },
        {
            id: '17',
            name: 'Воплощать\nзамыслы',
            attributes: ['Исполнение', '', 'Воплощать замыслы'],
            category: 'Исполнение',
            draggable: true,
            symbolSize: 25,
            itemStyle: null,
        },
        {
            id: '18',
            name: 'Работать\nпо четким\nинструкциям',
            attributes: ['Исполнение', '', 'Работать по четким инструкциям'],
            category: 'Исполнение',
            draggable: true,
            symbolSize: 25,
            itemStyle: null,
        },
        {
            id: '19',
            name: 'Оттачивать\nвладение\nнавыками',
            attributes: ['Исполнение', '', 'Оттачивать владение навыками'],
            category: 'Исполнение',
            draggable: true,
            symbolSize: 25,
            itemStyle: null,
        },
        {
            id: '21',
            name: 'Давать\nконструктивную\nкритику',
            attributes: ['Критика и контроль', '', 'Давать конструктивную критику'],
            category: 'Критика и контроль',
            draggable: true,
            symbolSize: 25,
            itemStyle: null,
        },
        {
            id: '22',
            name: 'Работать с рисками\nи вопросами\nбезопасности',
            attributes: ['Критика и контроль', '', 'Работать с рисками и вопросами безопасности'],
            category: 'Критика и контроль',
            draggable: true,
            symbolSize: 25,
            itemStyle: null,
        },
        {
            id: '23',
            name: 'Фокусировать\nвнимание',
            attributes: ['Критика и контроль', '', 'Фокусировать внимание'],
            category: 'Критика и контроль',
            draggable: true,
            symbolSize: 25,
            itemStyle: null,
        },
        {
            id: '24',
            name: 'Отслеживать\nошибки',
            attributes: ['Критика и контроль', '', 'Отслеживать ошибки'],
            category: 'Критика и контроль',
            draggable: true,
            symbolSize: 25,
            itemStyle: null,
        },
        {
            id: '25',
            name: 'Контролировать\nпроцессы,\nудерживать рамки',
            attributes: ['Критика и контроль', '', 'Контролировать процессы, удерживать рамки'],
            category: 'Критика\nи контроль',
            draggable: true,
            symbolSize: 25,
            itemStyle: null,
        },
        {
            id: '26',
            name: 'Брать на себя\nответственность',
            attributes: ['Организация', '', 'Брать на себя ответственность'],
            category: 'Организация',
            draggable: true,
            symbolSize: 25,
            itemStyle: null,
        },
        {
            id: '27',
            name: 'Управлять\nкомандой',
            attributes: ['Организация', '', 'Управлять командой'],
            category: 'Организация',
            draggable: true,
            symbolSize: 25,
            itemStyle: null,
        },
        {
            id: '28',
            name: 'Принимать\nрешения',
            attributes: ['Организация', '', 'Принимать решения'],
            category: 'Организация',
            draggable: true,
            symbolSize: 25,
            itemStyle: null,
        },
        {
            id: '29',
            name: 'Добывать\nресурсы,\nсредства',
            attributes: ['Организация', '', 'Добывать ресурсы, средства'],
            category: 'Организация',
            draggable: true,
            symbolSize: 25,
            itemStyle: null,
        },
        {
            id: '30',
            name: 'Стремиться\nдостигать\nрезультата',
            attributes: ['Организация', '', 'Стремиться достигать результата'],
            category: 'Организация',
            draggable: true,
            symbolSize: 25,
            itemStyle: null,
        },
        {
            id: '31',
            name: 'Научное руководство,\nисследования',
            attributes: ['Мои задачи в рамках команды', '', 'Научное руководство, исследования'],
            category: 'Мои задачи в рамках команды',
            draggable: true,
            symbolSize: 25,
            itemStyle: null,
        },
        {
            id: '32',
            name: 'Программирование\n(фронт, бэк,\nбазы данных)',
            attributes: [
                'Мои задачи в рамках команды',
                '',
                'Программирование (фронт, бэк, базы данных)',
            ],
            category: 'Мои задачи в рамках команды',
            draggable: true,
            symbolSize: 25,
            itemStyle: null,
        },
        {
            id: '33',
            name: 'Работа\nс данными\nи алгоритмами',
            attributes: ['Мои задачи в рамках команды', '', 'Работа с данными и алгоритмами'],
            category: 'Мои задачи в рамках команды',
            draggable: true,
            symbolSize: 25,
            itemStyle: null,
        },
        {
            id: '34',
            name: 'Продажи,\nкоммерческая\nдеятельность ',
            attributes: ['Мои задачи в рамках команды', '', 'Продажи, коммерческая деятельность '],
            category: 'Мои задачи в рамках команды',
            draggable: true,
            symbolSize: 25,
            itemStyle: null,
        },
        {
            id: '35',
            name: 'Продвижение\nпродукта,\nPR, копирайтинг',
            attributes: [
                'Мои задачи в рамках команды',
                '',
                'Продвижение продукта, PR, копирайтинг',
            ],
            category: 'Мои задачи в рамках команды',
            draggable: true,
            symbolSize: 25,
            itemStyle: null,
        },
        {
            id: '36',
            name: 'Общее\nруководство\nпроектом',
            attributes: ['Мои задачи в рамках команды', '', 'Общее руководство проектом'],
            category: 'Мои задачи в рамках команды',
            draggable: true,
            symbolSize: 25,
            itemStyle: null,
        },
        {
            id: '37',
            name: 'Бизнес аналитика,\nанализ рынка',
            attributes: ['Мои задачи в рамках команды', '', 'Бизнес аналитика, анализ рынка'],
            category: 'Мои задачи в рамках команды',
            draggable: true,
            symbolSize: 25,
            itemStyle: null,
        },
        {
            id: '38',
            name: 'Финансы,\nкадровые,\nюридические вопросы',
            attributes: [
                'Мои задачи в рамках команды',
                '',
                'Финансы, кадровые, юридические вопросы',
            ],
            category: 'Мои задачи в рамках команды',
            draggable: true,
            symbolSize: 25,
            itemStyle: null,
        },
        {
            id: '39',
            name: 'Решение\nтехнологических\nзадач\n(в рамках проекта)',
            attributes: [
                'Мои задачи в рамках команды',
                '',
                'Решение технологических задач (в рамках проекта)',
            ],
            category: 'Мои задачи в рамках команды',
            draggable: true,
            symbolSize: 25,
            itemStyle: null,
        },
        {
            id: '40',
            name: 'Социо-гуманитарные\nтехнологии\n(в рамках проекта)',
            attributes: [
                'Мои задачи в рамках команды',
                '',
                'Социо-гуманитарные технологии (в рамках проекта)',
            ],
            category: 'Мои задачи в рамках команды',
            draggable: true,
            symbolSize: 25,
            itemStyle: null,
        },
    ],
    links: [
        {
            id: '0',
            source: '1',
            target: '2',
        },
        {
            id: '1',
            source: '2',
            target: '3',
        },
        {
            id: '2',
            source: '3',
            target: '4',
        },
        {
            id: '3',
            source: '4',
            target: '5',
        },
        {
            id: '4',
            source: '5',
            target: '1',
        },
        {
            id: '5',
            source: '3',
            target: '4',
        },
        {
            id: '6',
            source: '6',
            target: '7',
        },
        {
            id: '6',
            source: '7',
            target: '8',
        },
        {
            id: '8',
            source: '8',
            target: '9',
        },
        {
            id: '9',
            source: '9',
            target: '10',
        },
        {
            id: '10',
            source: '6',
            target: '10',
        },
        {
            id: '11',
            source: '11',
            target: '12',
        },
        {
            id: '12',
            source: '12',
            target: '13',
        },
        {
            id: '13',
            source: '13',
            target: '14',
        },
        {
            id: '14',
            source: '14',
            target: '15',
        },
        {
            id: '15',
            source: '15',
            target: '11',
        },
        {
            id: '16',
            source: '16',
            target: '17',
        },
        {
            id: '17',
            source: '17',
            target: '18',
        },
        {
            id: '18',
            source: '18',
            target: '19',
        },
        {
            id: '19',
            source: '19',
            target: '16',
        },
        {
            id: '21',
            source: '21',
            target: '22',
        },
        {
            id: '22',
            source: '22',
            target: '23',
        },
        {
            id: '23',
            source: '23',
            target: '24',
        },
        {
            id: '24',
            source: '24',
            target: '25',
        },
        {
            id: '25',
            source: '25',
            target: '21',
        },
        {
            id: '26',
            source: '26',
            target: '27',
        },
        {
            id: '27',
            source: '27',
            target: '28',
        },
        {
            id: '28',
            source: '28',
            target: '29',
        },
        {
            id: '29',
            source: '29',
            target: '30',
        },
        {
            id: '30',
            source: '30',
            target: '26',
        },
        {
            id: '31',
            source: '31',
            target: '32',
        },
        {
            id: '32',
            source: '32',
            target: '33',
        },
        {
            id: '33',
            source: '33',
            target: '34',
        },
        {
            id: '34',
            source: '34',
            target: '31',
        },
        {
            id: '35',
            source: '35',
            target: '37',
        },
        {
            id: '37',
            source: '37',
            target: '38',
        },
        {
            id: '38',
            source: '38',
            target: '39',
        },
        {
            id: '39',
            source: '39',
            target: '40',
        },
        {
            id: '40',
            source: '40',
            target: '36',
        },
        {
            id: '41',
            source: '3',
            target: '6',
        },
        {
            id: '42',
            source: '8',
            target: '11',
        },
        {
            id: '43',
            source: '13',
            target: '16',
        },
        {
            id: '44',
            source: '18',
            target: '21',
        },
        {
            id: '45',
            source: '23',
            target: '26',
        },
        {
            id: '46',
            source: '28',
            target: '31',
        },
        {
            id: '47',
            source: '33',
            target: '36',
        },
        {
            id: '48',
            source: '38',
            target: '1',
        },
        {
            id: '49',
            source: '2',
            target: '27',
        },
    ],
};

export default graph;
