import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import * as serviceWorker from './serviceWorker';
import 'antd/dist/antd.css';
import './index.css';
import './css/index.scss';

import { createStore, Store } from 'redux';
import { Provider } from 'react-redux';
import reducers from './store/reducers';

import storeService from './services/storeService';
import { composeWithDevTools } from 'redux-devtools-extension';
import pjson from '../package.json';
import integrationService from './services/integrationService';
import actions from './store/actions/types';
import graylogService from './services/graylogServices';
import dispatcherService from './services/dispatcherService';

const App = React.lazy(() => import('./App'));

const store: Store = createStore(reducers, {}, composeWithDevTools());
storeService.setStore(store);

const root = document.getElementById('webbot');

let isFramed;
try {
    // eslint-disable-next-line no-restricted-globals
    isFramed = window !== window.top || document !== top.document || self.location !== top.location;
} catch (e) {
    isFramed = true;
}

if (isFramed) {
    dispatcherService.setParamsByIframe();
} else {
    dispatcherService.setParamsByElement(root);
}

(async () => {
    storeService.getStore().dispatch({
        type: actions.general.FETCH_CONFIG,
    });

    let loadConfigRequest = await integrationService.loadConfig();

    if (loadConfigRequest.status === 200) {
        const config = await loadConfigRequest.json();

        storeService.getStore().dispatch({
            type: actions.general.FETCH_CONFIG_SUCCESS,
            payload: config,
        });
    } else {
        let body = await loadConfigRequest.json();

        storeService.getStore().dispatch({
            type: actions.general.FETCH_CONFIG_ERROR,
            payload: body,
        });

        dispatcherService.setMode('warning');
        dispatcherService.setWarningType('get_config');

        await graylogService.sendMessage({
            short_message: `[${loadConfigRequest.status}] Request error: ${loadConfigRequest.url}`,
            full_message: `[${loadConfigRequest.status}] Request error: ${loadConfigRequest.url}`,
            _body: integrationService.buildPleBody(
                storeService.getStore().getState().general.integrationParams
            ),
            _body_result: body,
        });
    }
})();

ReactDOM.render(
    <Provider store={store}>
        <Suspense fallback={<></>}>
            <App />
        </Suspense>
    </Provider>,
    root
);

console.log(
    `%c Self portrait version: ${pjson.version}`,
    'background-color: #ffdd2d; color: #333;'
);

serviceWorker.unregister();
