//TODO: part of this is rather config than rules
const rules = {
    minNodes: 12,
    numberOfOptionsInCategories: 4,
    shouldRedirect: true,
    defaultRedirect:
        'https://steps.2035.university/collections/91ae2169-646b-4259-9df3-f14b830c170b',
    redirectRules: [
        {
            ifNode: '31', //журналистика и соцсети
            andValue: 'Очень интересно',
            redirectUrl:
                'https://steps.2035.university/collections/70abd3bb-8704-4d72-a8fd-399b6378ebce',
        },
        {
            ifNode: '32', //инновационные технологии
            andValue: 'Очень интересно',
            redirectUrl:
                'https://steps.2035.university/collections/2661a613-ad84-452a-b25f-9f26e7c0bf18',
        },
        {
            ifNode: '33', //разработка ИТ решений
            andValue: 'Очень интересно',
            redirectUrl:
                'https://steps.2035.university/collections/cb02fc3f-e21f-4573-9c39-11aceb5b0ddd',
        },
        {
            ifNode: '34', //бизнес
            andValue: 'Очень интересно',
            redirectUrl:
                'https://steps.2035.university/collections/e2c23afe-5460-491a-9463-2f53b428693e',
        },
        {
            ifNode: '36', //история
            andValue: 'Очень интересно',
            redirectUrl:
                'https://steps.2035.university/collections/7c2fa0e0-ef51-42cc-88bf-2d11c24efbf8',
        },
        {
            ifNode: '37', //путешествия
            andValue: 'Очень интересно',
            redirectUrl:
                'https://steps.2035.university/collections/74b90047-53c8-4583-b727-68c9ef9c10ea',
        },
        {
            ifNode: '38', //экология
            andValue: 'Очень интересно',
            redirectUrl:
                'https://steps.2035.university/collections/87060d32-6bf4-401b-a638-dc4d46c836bc',
        },
        {
            ifNode: '39', //вопросы человечности
            andValue: 'Очень интересно',
            redirectUrl:
                'https://steps.2035.university/collections/821f5fac-4cf0-4f8c-9e27-2e1d0262e95c',
        },
        {
            ifNode: '40', //творчество
            andValue: 'Очень интересно',
            redirectUrl:
                'https://steps.2035.university/collections/68d72aa6-bfa0-4289-88db-b9653cb63322',
        },
        {
            ifNode: '31', //журналистика и соцсети
            andValue: 'Любопытно',
            redirectUrl:
                'https://steps.2035.university/collections/70abd3bb-8704-4d72-a8fd-399b6378ebce',
        },
        {
            ifNode: '32', //инновационные технологии
            andValue: 'Любопытно',
            redirectUrl:
                'https://steps.2035.university/collections/2661a613-ad84-452a-b25f-9f26e7c0bf18',
        },
        {
            ifNode: '33', //разработка ИТ решений
            andValue: 'Любопытно',
            redirectUrl:
                'https://steps.2035.university/collections/cb02fc3f-e21f-4573-9c39-11aceb5b0ddd',
        },
        {
            ifNode: '34', //бизнес
            andValue: 'Любопытно',
            redirectUrl:
                'https://steps.2035.university/collections/e2c23afe-5460-491a-9463-2f53b428693e',
        },
        {
            ifNode: '36', //история
            andValue: 'Любопытно',
            redirectUrl:
                'https://steps.2035.university/collections/7c2fa0e0-ef51-42cc-88bf-2d11c24efbf8',
        },
        {
            ifNode: '37', //путешествия
            andValue: 'Любопытно',
            redirectUrl:
                'https://steps.2035.university/collections/74b90047-53c8-4583-b727-68c9ef9c10ea',
        },
        {
            ifNode: '38', //экология
            andValue: 'Любопытно',
            redirectUrl:
                'https://steps.2035.university/collections/87060d32-6bf4-401b-a638-dc4d46c836bc',
        },
        {
            ifNode: '39', //вопросы человечности
            andValue: 'Любопытно',
            redirectUrl:
                'https://steps.2035.university/collections/821f5fac-4cf0-4f8c-9e27-2e1d0262e95c',
        },
        {
            ifNode: '40', //творчество
            andValue: 'Любопытно',
            redirectUrl:
                'https://steps.2035.university/collections/68d72aa6-bfa0-4289-88db-b9653cb63322',
        },
    ],
};

export default rules;
