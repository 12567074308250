import ICategory from '../../types/Graph/Category';

const categories: ICategory[] = [
    {
        name: 'Знания и мышление',
        required: false,
        options: [
            'Моя сильная сторона',
            'В принципе есть',
            'Очень сильно не хватает',
            'Желательно приобрести',
            'Не нужно',
        ],
    },
    {
        name: 'Личные качества',
        required: false,
        options: [
            'Моя сильная сторона',
            'В принципе есть',
            'Очень сильно не хватает',
            'Желательно приобрести',
            'Не нужно',
        ],
    },
    {
        name: 'Профессиональные качества',
        required: false,
        options: [
            'Моя сильная сторона',
            'В принципе есть',
            'Очень сильно не хватает',
            'Желательно приобрести',
            'Не нужно',
        ],
    },
];

export default categories;
