import INode from '../types/Graph/Node';
import storeService from './storeService';

const lrs = 'https://my.2035.university';

const environment = {
    LRSResultFormat: `${lrs}/xapi/v1/results/selfportrait`,
    proxyHost: 'https://bypass.2035.university/api/activity',
    t: 'c8d46d341bea4fd5bff866a65ff8aea9',
    production: true,
};

interface IframeParams {
    token: string;
    unti_id: string;
    activity_uuid: string;
}

class IntegrationService {
    public sendStart(params: IframeParams): Promise<Response> {
        return fetch(`${environment.proxyHost}/${params.activity_uuid}/start`, {
            body: this.buildPleBody(params),
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
        });
    }

    public sendEnd(params: IframeParams): Promise<Response> {
        return fetch(`${environment.proxyHost}/${params.activity_uuid}/end`, {
            body: this.buildPleBody(params),
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
        });
    }

    public sendResult(params: IframeParams, result: INode[][]): Promise<Response> {
        return fetch(`${environment.proxyHost}/${params.activity_uuid}/lrsdata`, {
            body: this.buildLrsBody(params, result),
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
        });
    }

    public loadConfig() {
        const currentState = storeService.getStore().getState().general;
        const { activity_uuid } = currentState.integrationParams;

        return fetch(
            'https://bypass.2035.university/api/activity/' + activity_uuid + '/get_config'
        );
    }

    public reportProgress(params: IframeParams, progress: number): Promise<Response> {
        return fetch(`${environment.proxyHost}/${params.activity_uuid}/progress`, {
            body: JSON.stringify({
                ...JSON.parse(this.buildPleBody(params)),
                progress: progress,
            }),
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
        });
    }

    public buildLrsBody(params: IframeParams, result: any) {
        const extensions = {
            [process.env.REACT_APP_EXTENSIONS_URL!]: result,
        };

        return JSON.stringify({
            unti_id: params.unti_id,
            token: params.token,
            t: environment.t,
            extensions,
        });
    }

    public buildPleBody(params: IframeParams) {
        return JSON.stringify({
            unti_id: params.unti_id,
            token: params.token,
            t: environment.t,
        });
    }
}

const integrationService = new IntegrationService();

export default integrationService;
