import IGraph from '../../types/Graph/Graph';

const graph: IGraph = {
    nodes: [
        {
            id: '1',
            name: 'Всесторонний и\nдетальный анализ\nзадачи',
            attributes: [
                'Знания и мышление',
                'При решении задачи вы рассматриваете ее со всех сторон; последовательно выделяете и рассматриваете разные аспекты этой задачи; копаетесь в задаче, стараясь увидеть все нюансы.',
                'Всесторонний и детальный анализ задачи',
            ],
            category: 'Знания и мышление',
            draggable: true,
            symbolSize: 25,
            itemStyle: null,
        },
        {
            id: '2',
            name: 'Склонность\nанализировать свои\nдействия/\nрефлексивность',
            attributes: [
                'Знания и мышление',
                'Вам свойственно задаваться вопросом, почему вы поступили именно так, а не иначе, какие последствия может иметь ваше действие, как можно было поступить по-другому в этих же условиях и чем ваш вариант лучше; какие эмоции у вас вызывает это действие.',
                'Склонность анализировать свои действия/рефлексивность',
            ],
            category: 'Знания и мышление',
            draggable: true,
            symbolSize: 25,
            itemStyle: null,
        },
        {
            id: '3',
            name: 'Широкий кругозор\nв разных областях',
            attributes: [
                'Знания и мышление',
                'Вам интересно узнавать новое в самых разных сферах и технологиях; вы с удовольствием читаете о том, что для вас неизвестно и получаете опыт в новой для себя сфере, но не погружаетесь так глубоко, чтобы считать себя экспертом.',
                'Широкий кругозор в разных областях',
            ],
            category: 'Знания и мышление',
            draggable: true,
            symbolSize: 25,
            itemStyle: null,
        },
        {
            id: '4',
            name: 'Креативность\nв решении задач',
            attributes: [
                'Знания и мышление',
                'Вы часто пользуетесь неожиданными методами и средствами для решения задачи; смотрите на вещи с необычного ракурса.',
                'Креативность в решении задач',
            ],
            category: 'Знания и мышление',
            draggable: true,
            symbolSize: 25,
            itemStyle: null,
        },
        {
            id: '5',
            name: 'Коммуникабельность',
            attributes: [
                'Личные качества',
                'Вы всегда открыты к взаимодействию; вам легко и приятно общаться с самыми разными людьми и получать от них обратную связь; вы с легкостью понимаете то, что до вас пытаются донести самые разные собеденики.',
                'Коммуникабельность',
            ],
            category: 'Личные качества',
            draggable: true,
            symbolSize: 25,
            itemStyle: null,
        },
        {
            id: '6',
            name: 'Грамотная\nрасстановка\nприоритетов',
            attributes: [
                'Личные качества',
                'Работая в условиях множества задач, вы всегда понимаете, чему стоит уделить больше времени и сил, а что можно сделать с минимальными затратами.',
                'Грамотная расстановка приоритетов',
            ],
            category: 'Личные качества',
            draggable: true,
            symbolSize: 25,
            itemStyle: null,
        },
        {
            id: '7',
            name: 'Контроль\nнад эмоциями',
            attributes: [
                'Личные качества',
                'Сталкиваясь с ситуацией, вызывающей у вас бурю эмоций, положительных или отрицательных, вы умеете сдерживать свою речь, движения, мимику, не перенося их на окружающих.',
                'Контроль над эмоциями',
            ],
            category: 'Личные качества',
            draggable: true,
            symbolSize: 25,
            itemStyle: null,
        },
        {
            id: '8',
            name: 'Настойчивость\nв преодолении\nтрудностей',
            attributes: [
                'Личные качества',
                'Вас не пугают задачи с неизвестным способом решения, вы не отказываетесь от таких задач; вам нравится преодолевать сложности и прикладывать все свои усилия для того, чтобы устранить трудность со своего пути.',
                'Настойчивость в преодолении трудностей',
            ],
            category: 'Личные качества',
            draggable: true,
            symbolSize: 25,
            itemStyle: null,
        },
        {
            id: '9',
            name: 'Высокая скорость\nвыполнения задач',
            attributes: [
                'Профессиональные качества',
                'Если перед вами поставлена задача, вы стараетесь потратить как можно меньше времени на то, чтобы достичь хорошего результата; для вас важно не тратить лишнего времени на посторонние дела, а скорее представить достойный результат.',
                'Высокая скорость выполнения задач',
            ],
            category: 'Профессиональные качества',
            draggable: true,
            symbolSize: 25,
            itemStyle: null,
        },
        {
            id: '10',
            name: 'Лидерская\nпозиция',
            attributes: [
                'Профессиональные качества',
                'Вы стремитесь быть лучшим среди коллектива при решении задачи; для вас важно признание ваших достижений и вы готовы прикладывать много усилий, чтобы добиться лидерской позиции.',
                'Лидерская позиция',
            ],
            category: 'Профессиональные качества',
            draggable: true,
            symbolSize: 25,
            itemStyle: null,
        },
        {
            id: '11',
            name: 'Высокий\nуровень\nответственности',
            attributes: [
                'Профессиональные качества',
                'Приступая к выполнению любой задачи вы стремитесь выполнить ее так, чтобы максимально удовлетворить запрос заказчика; вы чувствуете, что именно вы будете виноваты, если что-то пойдет не так.',
                'Высокий уровень ответственности',
            ],
            category: 'Профессиональные качества',
            draggable: true,
            symbolSize: 25,
            itemStyle: null,
        },
        {
            id: '12',
            name: 'Сфокусированность\nпри выполнении\nзадачи',
            attributes: [
                'Профессиональные качества',
                'Когда вы садитесь за решение задачи, то погружаетесь в нее с головой; вам не хочется отвлекаться, и мысли о задаче не выходят из головы даже во время перерыва',
                'Сфокусированность при выполнении задачи',
            ],
            category: 'Профессиональные качества',
            draggable: true,
            symbolSize: 25,
            itemStyle: null,
        },
        {
            id: '13',
            name: 'Умение работать\nв условиях\nнеопределенности',
            attributes: [
                'Профессиональные качества',
                'Вас завораживает работа с новыми задачами, когда непонятно, как именно решать задачу, или что станет итогом; вы не переживаете и не расстраиваетесь, если сталкиваетесь с задачей, с которой никогда до этого не встречались.',
                'Умение работать в условиях неопределенности',
            ],
            category: 'Профессиональные качества',
            draggable: true,
            symbolSize: 25,
            itemStyle: null,
        },
        {
            id: '14',
            name: 'Планирование\nна долгий срок',
            attributes: [
                'Профессиональные качества',
                'Вам свойственно планировать рабочие дела далеко наперед; при планировании вы всегда видите главную цель, которой подчинены все мелкие, текущие дела; когда вы решаете какую-либо задачу, вы понимаете, как она будет способствовать достижению главной цели.',
                'Планирование на долгий срок',
            ],
            category: 'Профессиональные качества',
            draggable: true,
            symbolSize: 25,
            itemStyle: null,
        },
        {
            id: '15',
            name: 'Работа\nпо четким\nинструкциям',
            attributes: [
                'Профессиональные качества',
                'Вам проще и приятнее работать, когда четко известно, какие именно шаги нужно сделать для решения задачи; вам свойственно систематизировать, упорядочивать и протоколировать все рабочие дела и процессы.',
                'Работа по четким инструкциям',
            ],
            category: 'Профессиональные качества',
            draggable: true,
            symbolSize: 25,
            itemStyle: null,
        },
    ],
    links: [
        {
            id: '0',
            source: '1',
            target: '6',
        },
        {
            id: '1',
            source: '1',
            target: '12',
        },
        {
            id: '2',
            source: '1',
            target: '15',
        },
        {
            id: '3',
            source: '2',
            target: '6',
        },
        {
            id: '4',
            source: '2',
            target: '7',
        },
        {
            id: '5',
            source: '3',
            target: '4',
        },
        {
            id: '6',
            source: '3',
            target: '5',
        },
        {
            id: '7',
            source: '3',
            target: '13',
        },
        {
            id: '8',
            source: '4',
            target: '9',
        },
        {
            id: '9',
            source: '4',
            target: '13',
        },
        {
            id: '10',
            source: '5',
            target: '7',
        },
        {
            id: '11',
            source: '5',
            target: '10',
        },
        {
            id: '12',
            source: '6',
            target: '8',
        },
        {
            id: '13',
            source: '6',
            target: '11',
        },
        {
            id: '14',
            source: '6',
            target: '12',
        },
        {
            id: '15',
            source: '6',
            target: '14',
        },
        {
            id: '16',
            source: '7',
            target: '8',
        },
        {
            id: '17',
            source: '7',
            target: '10',
        },
        {
            id: '18',
            source: '8',
            target: '10',
        },
        {
            id: '19',
            source: '8',
            target: '11',
        },
        {
            id: '20',
            source: '8',
            target: '12',
        },
        {
            id: '21',
            source: '8',
            target: '13',
        },
        {
            id: '22',
            source: '9',
            target: '12',
        },
        {
            id: '23',
            source: '9',
            target: '15',
        },
        {
            id: '24',
            source: '10',
            target: '11',
        },
        {
            id: '25',
            source: '10',
            target: '12',
        },
        {
            id: '26',
            source: '11',
            target: '12',
        },
    ],
};

export default graph;
