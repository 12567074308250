import actions from '../actions/types';
import GeneralState from '../../types/GeneralState';
import StoreAction from '../../types/StoreAction';
//import graphService from '../../services/graphService';
//import dataService from '../../services/dataService';
import { updateObject } from './utility';

const categories = require('../../data/' +
    (process.env.REACT_APP_DATA! || 'general') +
    '/categories').default;
const rules = require('../../data/' + (process.env.REACT_APP_DATA! || 'general') + '/rules')
    .default;
const graph = require('../../data/' + (process.env.REACT_APP_DATA! || 'general') + '/graph')
    .default;
const strings = require('../../data/' + (process.env.REACT_APP_DATA! || 'general') + '/strings')
    .default;

// const options = dataService.getOptionList();
// const nodes = dataService.getChosenNodeList();
//const graphOption = graphService.getGraph();

const initialState: GeneralState = {
    fetchConfig: false,
    fetchConfigError: null,
    categories: categories,
    graph: graph,
    rules: rules,
    strings: strings,
    graphOption: {},
    options: [],
    nodes: [[]],
    mode: 'select',
    warningType: 'none',
    shouldRedirect: false,
    integrationParams: {
        token: '',
        unti_id: '',
        activity_uuid: '',
    },
};

export default (state: GeneralState = initialState, action: StoreAction) => {
    switch (action.type) {
        case actions.general.SET_OPTIONS: {
            let currentState = Object.assign({}, state);
            currentState.options = action.payload;

            return updateObject(state, currentState);
        }
        case actions.general.SET_NODES: {
            let currentState = Object.assign({}, state);
            currentState.nodes = action.payload;

            return updateObject(state, currentState);
        }
        case actions.general.SET_GRAPH_OPTION: {
            let currentState = Object.assign({}, state);
            currentState.graphOption = action.payload;

            return updateObject(state, currentState);
        }
        case actions.general.SET_MODE: {
            let currentState = Object.assign({}, state);
            currentState.mode = action.payload;

            return updateObject(state, currentState);
        }
        case actions.general.SET_INTEGRATION_PARAMS: {
            let currentState = Object.assign({}, state);
            currentState.integrationParams = action.payload;

            return updateObject(state, currentState);
        }
        case actions.general.SET_SHOULD_REDIRECT: {
            let currentState = Object.assign({}, state);
            currentState.shouldRedirect = action.payload;

            return updateObject(state, currentState);
        }
        case actions.general.FETCH_CONFIG: {
            let currentState = Object.assign({}, state);
            currentState.fetchConfig = true;

            return updateObject(state, currentState);
        }
        case actions.general.FETCH_CONFIG_SUCCESS: {
            let currentState = Object.assign({}, state);

            currentState = {
                ...currentState,
                ...action.payload,
            };
            currentState.fetchConfig = false;

            return updateObject(state, currentState);
        }
        case actions.general.FETCH_CONFIG_ERROR: {
            let currentState = Object.assign({}, state);
            currentState.fetchConfigError = action.payload;
            currentState.fetchConfig = false;

            return updateObject(state, currentState);
        }
        case actions.general.SET_WARNING_TYPE: {
            let currentState = Object.assign({}, state);
            currentState.warningType = action.payload;

            return updateObject(state, currentState);
        }
        default:
            return state;
    }
};
